import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Dialog } from "da-design-system";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import ApplicationsPickerModalListItem from "./ApplicationsPickerModalListItem";
import SyncListButton from "./SyncListButton";

declare type ApplicationPickerModalProps = {
  open: boolean;
  onClose: () => void;
};

function ApplicationPickerModal({
  open,
  onClose,
}: ApplicationPickerModalProps): JSX.Element {
  const { applications, setSelectedApplicationId, isLoading } =
    useApplicationsContext();

  const handleItemClick: (applicationId: string) => void = (applicationId) => {
    setSelectedApplicationId(applicationId);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Choix de l'application</DialogTitle>
      {isLoading ? (
        <DialogContent
          dividers
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent dividers>
          {applications?.length ? (
            <List sx={{ p: 0 }}>
              {applications.map((application) => (
                <ApplicationsPickerModalListItem
                  onClick={handleItemClick}
                  key={application.id}
                  application={application}
                />
              ))}
            </List>
          ) : (
            <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
              Aucune application
            </Typography>
          )}
          <SyncListButton topSpacing={Boolean(applications?.length)} />
        </DialogContent>
      )}

      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApplicationPickerModal;
