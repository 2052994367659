import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

import Layout from "./components/Layout/Layout";
import LoginPage from "./pages/public/LoginPage";
import { privateRoutes, publicRoutes } from "./utils/routes";
import { useAuthenticationStateContext } from "./contexts/AuthenticationStateContext";
import CantAccessSnackbar from "./components/Snackbar/CantAccessSnackbar";
import NotFoundPage from "./pages/common/NotFoundPage";
import { getRealm } from "./utils/reformator";

const Router: React.FunctionComponent = () => {
  const { initialized } = useKeycloak();
  const { authenticationState } = useAuthenticationStateContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (authenticationState.isAuthenticated && !authenticationState.hasAccess) {
      enqueueSnackbar(<CantAccessSnackbar />, {
        persist: true,
      });
    }
  }, [authenticationState, enqueueSnackbar]);

  if (initialized) {
    return (
      <BrowserRouter basename={getRealm()}>
        {authenticationState?.isAuthenticated &&
        authenticationState?.hasAccess ? (
          <Route
            render={(props) => (
              <Layout {...props}>
                <Switch>
                  {privateRoutes.map((route) => (
                    <Route
                      exact
                      path={route.path}
                      key={route.key}
                      component={(props: any) => <route.Component {...props} />}
                    />
                  ))}
                  <Route
                    component={(props: any) => <NotFoundPage {...props} />}
                  />
                </Switch>
              </Layout>
            )}
          />
        ) : (
          <Route
            render={(props) => (
              <Layout hideHeader {...props}>
                <Switch>
                  {publicRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      key={route.key}
                      component={route.Component}
                    />
                  ))}
                  <Route component={LoginPage} />
                </Switch>
              </Layout>
            )}
          />
        )}
      </BrowserRouter>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <CircularProgress />
      <Typography sx={{ marginTop: 2 }} variant="body1">
        Chargement de l'application
      </Typography>
    </Box>
  );
};

export default Router;
