import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

declare type ProfileCardItemProps = {
  label: string;
  value: string | null;
};

export default function ProfileCardItem({
  label,
  value,
}: ProfileCardItemProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="body1">
        {value ? value : <Skeleton variant="text" sx={{ width: "150px" }} />}
      </Typography>
    </Box>
  );
}
