import { Button, TableCell, TableRow } from "@mui/material";
import { Users } from "../../@types";

declare type UsersListItemProps = {
  users: Users;
  showHistory: (usersId: string) => void;
};

function UsersListItem({ users, showHistory }: UsersListItemProps) {
  const onClick = () => {
    showHistory(users.id);
  };

  return (
    <TableRow key={users.id}>
      <TableCell>{users.lastName}</TableCell>
      <TableCell>{users.firstName}</TableCell>
      <TableCell>{users.email}</TableCell>
      <TableCell>{users.username}</TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Button variant="outlined" size="small" onClick={onClick}>
          Historique
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default UsersListItem;
