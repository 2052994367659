import { useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { REDIRECT_URI } from "../../utils/keycloak";
import ContentLayout from "../../components/Layout/ContentLayout";
import ProfileCard from "../../components/Profile/ProfileCard";
import { getRealm } from "../../utils/reformator";

function ProfilePage(): JSX.Element {
  const { keycloak } = useKeycloak();

  const logout = useCallback(() => {
    keycloak?.logout({
      redirectUri: `${REDIRECT_URI}/${getRealm()}`,
    });
  }, [keycloak]);

  return (
    <ContentLayout>
      <Typography variant="h1">Profil</Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mb: 4, width: "100%" }}>
            <ProfileCard />
          </Box>
          <Button onClick={logout}>Déconnexion</Button>
        </Box>
      </Box>
    </ContentLayout>
  );
}

export default ProfilePage;
