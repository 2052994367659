import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ContentLayout from "../../components/Layout/ContentLayout";

function NotFoundPage(): JSX.Element {
  return (
    <ContentLayout>
      <Typography variant="h1">Page non trouvée</Typography>
      <Box>
        <Typography variant="body1">
          Oups... La page que vous recherchez ne semble pas exister
        </Typography>
      </Box>
    </ContentLayout>
  );
}

export default NotFoundPage;
