import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { Users } from "../@types";
import ErrorSnackbar from "../components/Snackbar/ErrorSnackbar";
import { searchUsers } from "../services/UsersService";

export type UsersContextProps = {
  isLoading: boolean;
  users: Users[] | null;
  setUsers: Dispatch<SetStateAction<Users[] | null>>;
  searchedUser: string;
  setSearchedUser: Dispatch<SetStateAction<string>>;
  searchNewUsers: (searchedUser: string, page: number, perPage: number) => void;
  total: number | null;
};

const UsersContext = createContext<UsersContextProps>({
  isLoading: false,
  users: null,
  setUsers: () => {},
  searchedUser: "",
  setSearchedUser: () => {},
  searchNewUsers: () => {},
  total: 0,
});

declare type UsersContextProviderProps = {
  children: React.ReactNode;
};

export function UsersContextProvider({
  children,
}: UsersContextProviderProps): JSX.Element {
  const [users, setUsers] = useState<Users[] | null>(null);
  const [searchedUser, setSearchedUser] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [total, setTotal] = useState<number | null>(null);

  const fetchUsers = useCallback(
    (search: string, page: number, perPage: number) => {
      if (search && search !== "") {
        setIsLoading(true);
        setUsers(null);
        searchUsers(search, {
          currentPage: page,
          currentPerPage: perPage,
        })
          .then((res) => {
            const data = res?.data?.data || [];
            if (data?.length) {
              const _users: Users[] = data as Users[];
              setUsers(_users);
              setTotal(res.data.total);
            } else {
              setUsers([]);
              setTotal(0);
            }
          })
          .catch((e) => {
            setUsers([]);
            setTotal(0);
            setSearchedUser("");
            enqueueSnackbar(<ErrorSnackbar />);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [enqueueSnackbar]
  );

  const value: UsersContextProps = useMemo(
    () => ({
      isLoading,
      users,
      setUsers,
      searchedUser,
      setSearchedUser,
      searchNewUsers: fetchUsers,
      total,
    }),
    [
      isLoading,
      users,
      setUsers,
      searchedUser,
      setSearchedUser,
      total,
      fetchUsers,
    ]
  );
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
}

export const useUsersContext = () => useContext(UsersContext);

export default UsersContext;
