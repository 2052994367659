import { useMemo } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CobaltIcon } from "da-design-system";

import { getRouteByKey } from "../../../utils/routes";
import IRoute from "../../../interfaces/route";

declare type HeaderItemProps = {
  routeKey: string;
};

function HeaderItem({ routeKey }: HeaderItemProps): JSX.Element {
  const history = useHistory();

  const route: IRoute | null = useMemo(
    () => getRouteByKey(routeKey),
    [routeKey]
  );
  const routeMatch = useRouteMatch(route?.path || "");

  const finalMatch =
    route?.path === "/"
      ? history.location.pathname === "/"
      : Boolean(routeMatch);

  const handleClick: () => void = () => {
    if (route?.path) {
      history.push(route.path);
    }
  };

  if (route) {
    return (
      <Button
        variant="text"
        startIcon={route.icon && <CobaltIcon name={route.icon} />}
        onClick={handleClick}
        sx={
          finalMatch
            ? {
                color: "cobalt.hover",
                backgroundColor: "cobalt.bleuDigital05",
              }
            : {}
        }
      >
        <Typography variant="h5">{route.label}</Typography>
      </Button>
    );
  }
  return <div />;
}

export default HeaderItem;
