import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import HistoriesModal from "../../components/Histories/HistoriesModal";
import TermsPreviewModal from "../../components/Terms/TermsPreviewModal";
import UsersListComponent from "../../components/Users/UsersListComponent";
import { useHistoriesContext } from "../../contexts/HistoriesContext";
import { useUsersContext } from "../../contexts/UsersContext";

function UsersList(): JSX.Element {
  const [searching, setSearching] = useState<boolean>(false);
  const [research, setResearch] = useState<string>("");
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
  const [openPreviewTermsDialog, setOpenPreviewTermsDialog] =
    useState<boolean>(false);
  const [selectedTermsId, setSelectedTermsId] = useState<string>("");
  const {
    searchedUser,
    searchNewUsers,
    setSearchedUser,
    users,
    isLoading,
    total,
  } = useUsersContext();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    perPage: 10,
  });

  const fetchParams = useMemo(
    () => ({
      searchedUser: searchedUser,
      page: paginationParams.page,
      perPage: paginationParams.perPage,
    }),
    [searchedUser, paginationParams]
  );

  const { setSelectedUserId } = useHistoriesContext();

  useEffect(() => {
    searchNewUsers(
      fetchParams.searchedUser,
      fetchParams.page,
      fetchParams.perPage
    );
  }, [searchNewUsers, fetchParams, paginationParams]);

  const showHistory = (userId: string) => {
    setSelectedUserId(userId);
    setOpenHistoryModal(true);
  };

  const searchingUsers = () => {
    setSearchedUser(research);
    setSearching(true);
  };

  const handleChangeResearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setResearch(e.target.value || "");
  };

  const onCloseHistoryModal = () => {
    setOpenHistoryModal(false);
    setSelectedUserId("");
  };

  const handlePreviewTerms = useCallback((termsId: string): void => {
    setSelectedTermsId(termsId);
    setOpenPreviewTermsDialog(true);
  }, []);

  const handleCloseTerms = useCallback((): void => {
    setSelectedTermsId("");
    setOpenPreviewTermsDialog(false);
  }, []);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPaginationParams((_prev) => ({ ..._prev, page: newPage }));
    },
    []
  );

  const handleChangePerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaginationParams((_prev) => ({
        ..._prev,
        page: 0,
        perPage: parseInt(event.target.value, 10),
      }));
    },
    []
  );

  return (
    <>
      <UsersListComponent
        research={research}
        searching={searching}
        handleChangeResearch={handleChangeResearch}
        searchingUsers={searchingUsers}
        isLoading={isLoading}
        users={users}
        showHistory={showHistory}
        currentPerPage={paginationParams.perPage}
        currentPage={paginationParams.page}
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
        total={total}
      />

      <HistoriesModal
        open={openHistoryModal}
        handleClose={onCloseHistoryModal}
        handlePreviewTerms={handlePreviewTerms}
      />

      <TermsPreviewModal
        open={openPreviewTermsDialog}
        termsId={selectedTermsId}
        onClose={handleCloseTerms}
      />
    </>
  );
}

export default UsersList;
