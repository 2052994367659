import { AxiosResponse } from "axios";

import axiosApiInstance from "../utils/axios";
import { ApiEndpointParams, API_ENDPOINT } from "../@types";

export const ENDPOINTS: Record<string, API_ENDPOINT> = {
  FETCH_ALL: { url: "/applications", method: "GET" },
};

interface GetApplicationsApiEndpointParams extends ApiEndpointParams {
  synchronize?: boolean;
}

export const getApplications = ({
  synchronize = false,
}: GetApplicationsApiEndpointParams): Promise<AxiosResponse<any, any>> =>
  axiosApiInstance({ ...ENDPOINTS.FETCH_ALL, params: { synchronize } });
