import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { SxProps } from "@mui/system";

interface SplashCardProps {
  sx?: SxProps;
  children?: string | React.ReactNode;
  action?: () => void;
  actionText?: string;
}

function SplashCard({
  sx,
  children,
  action,
  actionText,
}: SplashCardProps): JSX.Element {
  return (
    <Card sx={{ display: "flex", padding: 5, flexDirection: "column", ...sx }}>
      {children && <Box sx={{ marginBottom: action ? 3 : 0 }}>{children}</Box>}
      {action && <Button onClick={action}>{actionText}</Button>}
    </Card>
  );
}

export default SplashCard;
