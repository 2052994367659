import { Alert } from "da-design-system";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

declare type CantAccessSnackbarProps = {
  handleDismiss?: () => void;
  text?: string;
};

export default function SuccessSnackbar({
  handleDismiss,
  text,
}: CantAccessSnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open
      message={
        <Alert severity="success" onClose={handleDismiss}>
          {text || (
            <Typography variant="body2">Action réalisée avec succès</Typography>
          )}
        </Alert>
      }
    />
  );
}
