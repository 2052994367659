import React from "react";
import Box from "@mui/material/Box";

declare type ContentLayoutProps = {
  children: React.ReactNode;
};

export default function ContentLayout({ children }: ContentLayoutProps) {
  return (
    <Box
      sx={{
        padding: 3,
        flex: 2,
        height: "100%",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "100%", "& > *:first-of-type": { mb: 2 } }}>
        {children}
      </Box>
    </Box>
  );
}
