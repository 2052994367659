import { ApiEndpointParams } from "../@types";

export const paramsFromObject = (obj: ApiEndpointParams) => {
  const { currentPage = 0, currentPerPage = 10 } = obj;

  return {
    per_page: currentPerPage,
    offset: currentPage * currentPerPage,
  };
};

export const getRealm = () => {
  const { pathname } = window.location;
  const parsed = pathname.split("/");
  return parsed[1] || "";
};
