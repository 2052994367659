import Typography from "@mui/material/Typography";

import ContentLayout from "../../components/Layout/ContentLayout";
import UsersList from "../../containers/Users/UsersList";
import { HistoriesContextProvider } from "../../contexts/HistoriesContext";
import { UsersContextProvider } from "../../contexts/UsersContext";

function UsersPage(): JSX.Element {
  return (
    <UsersContextProvider>
      <HistoriesContextProvider>
        <ContentLayout>
          <Typography variant="h1">Liste des utilisateurs</Typography>
          <UsersList />
        </ContentLayout>
      </HistoriesContextProvider>
    </UsersContextProvider>
  );
}
export default UsersPage;
