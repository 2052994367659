import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import SyncListButton from "./SyncListButton";

function ApplicationPickerLarge(): JSX.Element {
  const { applications, setSelectedApplicationId, selectedApplicationId } =
    useApplicationsContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Applications
          </ListSubheader>
        }
        sx={{ flex: 2, height: "100%", overflowY: "auto" }}
      >
        {applications !== null ? (
          applications?.length ? (
            applications.map((application) => (
              <ListItemButton
                onClick={() => {
                  setSelectedApplicationId(application.id);
                }}
                key={application.id}
                selected={application.id === selectedApplicationId}
              >
                <ListItemText primary={application.name} />
              </ListItemButton>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
                Aucune application
              </Typography>
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          bottom: "8px",
          position: "relative",
        }}
      >
        <SyncListButton />
      </Box>
    </Box>
  );
}

export default ApplicationPickerLarge;
