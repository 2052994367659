import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { CobaltIcon } from "da-design-system";

import TermsUploadModal from "./TermsUploadModal";
import { useApplicationsContext } from "../../contexts/ApplicationsContext";

declare type ImportTermsDialogButtonProps = {
  onImportSuccess: () => void;
};

export default function ImportTermsDialogButton({
  onImportSuccess,
}: ImportTermsDialogButtonProps) {
  const { selectedApplicationId } = useApplicationsContext();
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);

  const handleImportSuccess = useCallback(() => {
    onImportSuccess();
  }, [onImportSuccess]);

  if (selectedApplicationId) {
    return (
      <>
        <Button
          onClick={() => {
            setOpenUploadDialog(true);
          }}
          startIcon={<CobaltIcon name="plus" />}
        >
          Ajouter
        </Button>
        <TermsUploadModal
          onImportSuccess={handleImportSuccess}
          open={openUploadDialog}
          handleClose={() => {
            setOpenUploadDialog(false);
          }}
        />
      </>
    );
  }
  return (
    <Tooltip
      title="Vous devez sélectionner une application pour charger des CGU"
      arrow
    >
      <Box>
        <Button
          onClick={() => {
            setOpenUploadDialog(true);
          }}
          startIcon={<CobaltIcon name="plus" />}
          disabled
        >
          Ajouter
        </Button>
      </Box>
    </Tooltip>
  );
}
