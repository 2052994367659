import React from "react";
import ListItemButton from "@mui/material/ListItemButton";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import { Application } from "../../@types";

declare type ApplicationsPickerModalListItemProps = {
  onClick: (applicationId: string) => void;
  application: Application;
};

function ApplicationsPickerModalListItem({
  onClick,
  application,
}: ApplicationsPickerModalListItemProps): JSX.Element {
  const { selectedApplicationId } = useApplicationsContext();

  const handleClick = React.useCallback(() => {
    onClick(application.id);
  }, [onClick, application]);

  return (
    <ListItemButton
      onClick={handleClick}
      selected={application.id === selectedApplicationId}
    >
      {application.name}
    </ListItemButton>
  );
}

export default ApplicationsPickerModalListItem;
