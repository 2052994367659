import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
} from "react";
import { useSnackbar, SnackbarContent } from "notistack";

const RootSnackbar = forwardRef<
  HTMLDivElement,
  { id: string | number; message: string | React.ReactNode }
>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const childrenWithProps = Children.map(props.message, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { handleDismiss });
    }
    return child;
  });

  return <SnackbarContent ref={ref}>{childrenWithProps}</SnackbarContent>;
});

export default RootSnackbar;
