import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import { getApplicationTerms } from "../../services/TermsService";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";
import TermsListComponent from "../../components/Terms/TermsListComponent";
import TermsPreviewModal from "../../components/Terms/TermsPreviewModal";

declare type TermsListProps = {
  syncedAt?: string;
};

export default function TermsList({ syncedAt }: TermsListProps) {
  const { selectedApplicationId } = useApplicationsContext();
  const [isProcessing, setIsProcessing] = useState(true);
  const [terms, setTerms] = useState(null);
  const [total, setTotal] = useState<number | null>(null);
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    perPage: 10,
  });
  const { enqueueSnackbar } = useSnackbar();

  const fetchParams = useMemo(
    () => ({
      appplicationId: selectedApplicationId,
      page: paginationParams.page,
      perPage: paginationParams.perPage,
    }),
    [selectedApplicationId, paginationParams]
  );

  const [openPreviewTermsDialog, setOpenPreviewTermsDialog] =
    useState<boolean>(false);
  const [focusedTermsId, setFocusedTermsId] = useState<string>("");

  const handlePreviewTerms = useCallback((termsId: string): void => {
    setFocusedTermsId(termsId);
    setOpenPreviewTermsDialog(true);
  }, []);

  const handleCloseTerms = useCallback((): void => {
    setFocusedTermsId("");
    setOpenPreviewTermsDialog(false);
  }, []);

  const fetchTerms = useCallback(
    ({ appplicationId, page, perPage }) => {
      if (appplicationId) {
        setIsProcessing(true);
        getApplicationTerms(appplicationId, {
          currentPage: page,
          currentPerPage: perPage,
        })
          .then((res) => {
            setTerms(res.data.data);
            setTotal(res.data.total);
          })
          .catch(() => {
            enqueueSnackbar(
              <ErrorSnackbar text="Une erreur est survenue lors de la récupération des CGUs" />
            );
          })
          .finally(() => {
            setIsProcessing(false);
          });
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    setPaginationParams((_prev) => ({
      ..._prev,
      page: _prev.page !== 0 ? 0 : _prev.page,
    }));
  }, [syncedAt]);

  useEffect(() => {
    fetchTerms({ ...fetchParams });
  }, [fetchTerms, fetchParams]);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPaginationParams((_prev) => ({ ..._prev, page: newPage }));
    },
    []
  );

  const handleChangePerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaginationParams((_prev) => ({
        ..._prev,
        page: 0,
        perPage: parseInt(event.target.value, 10),
      }));
    },
    []
  );

  return (
    <>
      <TermsListComponent
        isProcessing={isProcessing}
        total={total}
        terms={terms}
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
        currentPerPage={paginationParams.perPage}
        currentPage={paginationParams.page}
        handlePreviewTerms={handlePreviewTerms}
      />
      <TermsPreviewModal
        open={openPreviewTermsDialog}
        termsId={focusedTermsId}
        onClose={handleCloseTerms}
      />
    </>
  );
}
