import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Dialog } from "da-design-system/";
import { useCallback, useEffect } from "react";
import { useHistoriesContext } from "../../contexts/HistoriesContext";
import UserHistoryList from "./HistoriesList";

declare type HistoriesModalProps = {
  open: boolean;
  handleClose: () => void;
  handlePreviewTerms: (termsId: string) => void;
};

function HistoriesModal({
  open,
  handleClose,
  handlePreviewTerms,
}: HistoriesModalProps): JSX.Element {
  const {
    selectedUserId,
    searchUserAttributes,
    userHistory,
    isLoadingHistory,
  } = useHistoriesContext();

  useEffect(() => {
    searchUserAttributes(selectedUserId);
  }, [selectedUserId, searchUserAttributes]);

  const onClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Historique des CGU acceptées</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {isLoadingHistory ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {userHistory?.length ? (
              <UserHistoryList handlePreviewTerms={handlePreviewTerms} />
            ) : (
              <Box sx={{ textAlign: "center", pt: 4, pb: 4 }}>
                <Typography>Aucun historique</Typography>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default HistoriesModal;
