import { Alert } from "da-design-system";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { useKeycloak } from "@react-keycloak/web";
import { getRealm } from "../../utils/reformator";
import { REDIRECT_URI } from "../../utils/keycloak";

declare type CantAccessSnackbarProps = {
  handleDismiss?: () => void;
};

export default function CantAccessSnackbar({
  handleDismiss,
}: CantAccessSnackbarProps) {
  const { keycloak } = useKeycloak();

  const handleLogout: () => void = () => {
    keycloak?.logout({
      redirectUri: `${REDIRECT_URI}/${getRealm()}`,
    });
    if (handleDismiss) handleDismiss();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open
      action={
        <>
          <Button onClick={handleLogout} variant="text">
            Déconnexion
          </Button>
          <Divider light orientation="vertical" />
          <Button onClick={handleDismiss} variant="text">
            Fermer
          </Button>
        </>
      }
      message={
        <>
          <Alert severity="error">
            <Typography variant="body2">
              Vous n'êtes pas habilité(e) à accéder à cette application
            </Typography>
          </Alert>
          <Divider light />
        </>
      }
    />
  );
}
