import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { Terms } from "../../@types";

declare type TermsListItemProps = {
  terms: Terms;
  handlePreview: (termsId: string) => void;
};

function TermsListItem({ terms, handlePreview }: TermsListItemProps) {
  const onClick = () => {
    handlePreview(terms.id);
  };

  return (
    <TableRow key={terms.id}>
      <TableCell>
        {Intl.DateTimeFormat("fr", {
          timeStyle: "short",
          dateStyle: "long",
        }).format(new Date(terms.created))}
      </TableCell>
      <TableCell>
        {terms.creator?.givenName}{" "}
        <span style={{ textTransform: "uppercase" }}>
          {terms.creator?.familyName}
        </span>
      </TableCell>
      <TableCell>
        {terms.fileName}
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Button variant="outlined" size="small" onClick={onClick}>
          Prévisualiser
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default TermsListItem;
