import { useState } from "react";
import { CobaltIcon } from "da-design-system";
import { useHistory } from "react-router";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import AppDrawer from "../Drawer/AppDrawer";
import HeaderItem from "./HeaderItem";
import ProfileBadge from "../Profile/ProfileBadge";

function Header(): JSX.Element {
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDrawerOpen: (state: boolean) => void = (state) => {
    setIsDrawerOpen(state);
  };

  const handleHome = () => {
    history.push("/");
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 2 }}
      >
        <Toolbar sx={{ height: 64 }}>
          <Box sx={{ flexGrow: 1, cursor: "pointer" }} onClick={handleHome}>
            <Typography variant="h4" sx={{ color: "cobalt.bleuDigital100" }}>
              DA-RGPD
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <HeaderItem routeKey="TERMS" />
            <HeaderItem routeKey="USERS" />
            <Divider
              sx={{
                marginRight: 2,
                marginTop: 0.75,
                marginBottom: 0.75,
                paddingRight: 1,
                paddingLeft: 1,
                height: "32px",
              }}
              orientation="vertical"
            />
            <ProfileBadge />
          </Box>
          <IconButton
            onClick={() => {
              setIsDrawerOpen((current: boolean) => !current);
            }}
            sx={{
              color: "cobalt.ultramarine",
              display: { xs: "block", md: "none" },
            }}
          >
            <CobaltIcon name="menu" style={{ fontSize: 20 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ height: 64 }} />
      <AppDrawer open={isDrawerOpen} toggleOpen={toggleDrawerOpen} />
    </Box>
  );
}

export default Header;
