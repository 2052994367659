import { useMemo } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, useRouteMatch } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/system";

import { getRouteByKey } from "../../../utils/routes";
import { CustomKeycloakTokenParsed } from "../../../utils/keycloak";

declare type ProfileBadgeProps = {
  orientation?: "horizontal" | "vertical";
  sx?: SxProps<Theme> | undefined;
  onClick?: (event?: Event) => void;
};

function ProfileBadge({
  orientation = "horizontal",
  sx,
  onClick,
}: ProfileBadgeProps): JSX.Element {
  const profilePath = useMemo(() => {
    const profileRoute = getRouteByKey("PROFILE");
    return profileRoute?.path;
  }, []);

  const { keycloak } = useKeycloak();
  const match = useRouteMatch(profilePath || "");
  const history = useHistory();

  const handleClick: () => void = () => {
    if (typeof onClick === "function") {
      onClick();
    } else if (profilePath) {
      history.push(profilePath);
    }
  };

  const userInitialsFromProfile: string = useMemo(() => {
    const token: CustomKeycloakTokenParsed | undefined = keycloak.tokenParsed;
    if (token) {
      const familyName: string = token.family_name || "";
      const givenName: string = token.given_name || "";
      return `${givenName?.substr(0, 1)}${familyName?.substr(
        0,
        1
      )}`.toUpperCase();
    }
    return "";
  }, [keycloak]);

  const userLastName: string = useMemo(() => {
    const token: CustomKeycloakTokenParsed | undefined = keycloak.tokenParsed;
    if (token) {
      const familyName: string = token.family_name || "";
      return familyName.toUpperCase();
    }
    return "";
  }, [keycloak]);

  if (orientation === "vertical") {
    return (
      <Button
        variant="text"
        sx={{ display: "flex", flexDirection: "column", height: "auto", ...sx }}
        onClick={handleClick}
      >
        <Box sx={{ marginBottom: 1 }}>
          <Avatar
            sx={{
              bgcolor: "cobalt.userBlue",
              width: "32px",
              height: "32px",
            }}
          >
            <Typography variant="h5" color="cobalt.white" sx={{ minWidth: 15 }}>
              {userInitialsFromProfile}
            </Typography>
          </Avatar>
        </Box>
        <Box>
          <Typography variant="h5">{userLastName}</Typography>
          <Typography variant="body2" sx={{ fontWeight: "normal" }}>
            Détails
          </Typography>
        </Box>
      </Button>
    );
  }

  return (
    <Button
      sx={{
        padding: 0.75,
        paddingRight: 2,
        marginRight: 1,
        color: match ? "cobalt.hover" : "inherit",
        backgroundColor: match ? "cobalt.bleuDigital05" : "inherit",
        ...sx,
      }}
      onClick={handleClick}
      variant="text"
      startIcon={
        <Avatar
          sx={{
            bgcolor: "cobalt.userBlue",
            width: "32px",
            height: "32px",
          }}
        >
          <Typography variant="h5" color="cobalt.white" sx={{ minWidth: 15 }}>
            {userInitialsFromProfile}
          </Typography>
        </Avatar>
      }
    >
      <Box sx={{ textAlign: "left", minWidth: 50 }}>
        <Typography variant="h5">{userLastName}</Typography>
        <Typography variant="body2" sx={{ fontWeight: "normal" }}>
          Détails
        </Typography>
      </Box>
    </Button>
  );
}

export default ProfileBadge;
