import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

import ApplicationPickerLarge from "../../components/Applications/ApplicationPickerLarge";
import { ApplicationsContextProdiver } from "../../contexts/ApplicationsContext";
import ApplicationPickerSmall from "../../components/Applications/ApplicationPickerSmall";
import TermsList from "../../containers/Terms/TermsList";
import ImportTermsDialogButton from "../../components/Terms/ImportTermsDialogButton";
import SuccessSnackbar from "../../components/Snackbar/SuccessSnackbar";
import ContentLayout from "../../components/Layout/ContentLayout";

function TermsPage(): JSX.Element {
  const [syncedAt, setSyncedAt] = useState(new Date().toString());
  const { enqueueSnackbar } = useSnackbar();

  const onImportSuccess = useCallback(() => {
    setSyncedAt(new Date().toString());
    enqueueSnackbar(<SuccessSnackbar text="La CGU a bien été ajoutée" />);
  }, [enqueueSnackbar]);

  return (
    <ApplicationsContextProdiver>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: 240,
            bgcolor: "background.paper",
            display: { xs: "none", md: "block" },
          }}
        >
          <ApplicationPickerLarge />
        </Box>
        <ContentLayout>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">Liste des CGU</Typography>
            <ImportTermsDialogButton onImportSuccess={onImportSuccess} />
          </Box>
          <ApplicationPickerSmall
            sx={{ mb: 2, display: { xs: "flex", md: "none" } }}
          />
          <TermsList syncedAt={syncedAt} />
        </ContentLayout>
      </Box>
    </ApplicationsContextProdiver>
  );
}

export default TermsPage;
