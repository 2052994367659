import { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import ApplicationPickerModal from "./ApplicationsPickerModal";

declare type ApplicationPickerSmallProps = {
  sx?: SxProps;
};

function ApplicationPickerSmall({
  sx,
}: ApplicationPickerSmallProps): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const { selectedApplicationId, applications } = useApplicationsContext();

  const selectedApplication: string = useMemo(() => {
    if (selectedApplicationId && applications) {
      const app = applications.find(
        (application) => application.id === selectedApplicationId
      );
      if (app) {
        return app.name;
      }
    }
    return "Application non définie";
  }, [selectedApplicationId, applications]);

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Typography variant="h5" sx={{ pb: 0, pr: 1 }}>
        Application :{" "}
      </Typography>
      <Button variant="text" size="small" onClick={handleClick}>
        {selectedApplication}
      </Button>
      <ApplicationPickerModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </Box>
  );
}

export default ApplicationPickerSmall;
