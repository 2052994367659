import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { UserHistory } from "../../@types";

declare type HistoriesListItemProps = {
  userHistoryItem: UserHistory;
  handlePreviewTerms: (termsId: string) => void;
};

function HistoriesListItem({
  userHistoryItem,
  handlePreviewTerms,
}: HistoriesListItemProps) {
  const onClick = (termsId: string) => {
    handlePreviewTerms(termsId);
  };

  return (
    <TableRow key={userHistoryItem.cgu.id}>
      <TableCell>{userHistoryItem.application.name}</TableCell>
      <TableCell>
        {Intl.DateTimeFormat("fr", {
          timeStyle: "short",
          dateStyle: "long",
        }).format(new Date(userHistoryItem.cgu.effectiveDate))}
      </TableCell>
      <TableCell>
        {new Intl.DateTimeFormat("fr", {
          timeStyle: "short",
          dateStyle: "long",
        }).format(new Date(userHistoryItem.acceptanceDate))}
      </TableCell>
      <TableCell>{userHistoryItem.cgu.fileName}</TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onClick(userHistoryItem.cgu.id)}
        >
          Prévisualiser
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default HistoriesListItem;
