import TermsPage from "../pages/private/TermsPage";
import ProfilePage from "../pages/private/ProfilePage";
import UsersPage from "../pages/private/UsersPage";
import IRoute from "../interfaces/route";

export const publicRoutes: IRoute[] = [];

export const privateRoutes: IRoute[] = [
  {
    key: "TERMS",
    path: "/",
    exact: true,
    Component: TermsPage,
    label: "CGU",
  },
  {
    key: "PROFILE",
    path: "/profil",
    exact: true,
    Component: ProfilePage,
    label: "Profil - tmp",
  },
  {
    key: "USERS",
    path: "/utilisateurs",
    exact: true,
    Component: UsersPage,
    label: "Utilisateurs",
  },
];

export const getRouteByKey: (key: string) => IRoute | null = (key) => {
  return privateRoutes.find((route) => route.key === key) || null;
};
