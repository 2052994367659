import axios, { AxiosRequestHeaders } from "axios";
import config from "../config/config";
import keycloak from "./keycloak";

const axiosApiInstance = axios.create({ baseURL: config.RGPD_API_URL });

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = keycloak?.token;
    const { Authorization, ...rest } = config.headers as AxiosRequestHeaders & {
      Authorization: string;
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
      ...rest,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;
