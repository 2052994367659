import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { Application } from "../@types";
import { getApplications } from "../services/ApplicationsService";
import ErrorSnackbar from "../components/Snackbar/ErrorSnackbar";

export type ApplicationsContextProps = {
  isLoading: boolean;
  applications: Application[] | null;
  setApplications: Dispatch<SetStateAction<Application[] | null>>;
  selectedApplicationId: string;
  setSelectedApplicationId: Dispatch<SetStateAction<string>>;
  // page: number;
  // handleChangePage: (page: number) => void;
  refreshWithSynchro: () => void;
};

const ApplicationsContext = createContext<ApplicationsContextProps>({
  isLoading: false,
  applications: null,
  setApplications: () => {},
  selectedApplicationId: "",
  setSelectedApplicationId: () => {},
  // page: 0,
  // handleChangePage: () => {},
  refreshWithSynchro: () => {},
});

declare type ApplicationsContextProviderProps = {
  children: React.ReactNode;
};

export function ApplicationsContextProdiver({
  children,
}: ApplicationsContextProviderProps): JSX.Element {
  const [applications, setApplications] = useState<Application[] | null>(null);
  const [selectedApplicationId, setSelectedApplicationId] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchApplications = useCallback(
    (synchronize: boolean = false) => {
      setIsLoading(true);
      setApplications(null);
      setSelectedApplicationId("");
      getApplications({ synchronize })
        .then((res) => {
          const { data } = res?.data || {};
          if (data?.length) {
            const _applications: Application[] = data as Application[];
            setApplications(_applications);
            setSelectedApplicationId(_applications[0].id);
          } else {
            setApplications([]);
          }
        })
        .catch(() => {
          setApplications([]);
          setSelectedApplicationId("");
          enqueueSnackbar(
            <ErrorSnackbar text="Une erreur est survenue lors de la récupération des applications" />
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchApplications(false);
  }, [fetchApplications]);

  const refreshWithSynchro: () => void = useCallback(() => {
    fetchApplications(true);
  }, [fetchApplications]);

  const value: ApplicationsContextProps = useMemo(
    () => ({
      isLoading,
      applications,
      setApplications,
      selectedApplicationId,
      setSelectedApplicationId,
      refreshWithSynchro,
    }),
    [
      isLoading,
      applications,
      setApplications,
      selectedApplicationId,
      setSelectedApplicationId,
      refreshWithSynchro,
    ]
  );
  return (
    <ApplicationsContext.Provider value={value}>
      {children}
    </ApplicationsContext.Provider>
  );
}

export const useApplicationsContext = () => useContext(ApplicationsContext);

export default ApplicationsContext;
