declare type Env = {
  RGPD_FRONT_URL: string;
  RGPD_API_URL: string;
};

const RGPD_DEV = {
  RGPD_FRONT_URL: "http://localhost:3000",
  RGPD_API_URL: "http://localhost:3001",
};

const RGPD_DEV_REMOTE_API = {
  RGPD_FRONT_URL: "http://localhost:3000",
  RGPD_API_URL: "https://api.recette.mediadoc.docaposte-agility.tech",
};

const RGPD_INTEGRATION = {
  RGPD_FRONT_URL: "https://recette.mediadoc.docaposte-agility.tech",
  RGPD_API_URL: "https://api.recette.mediadoc.docaposte-agility.tech",
};

const RGPD_RECETTE = {
  RGPD_FRONT_URL: "https://recette.mediadoc.docaposte-agility.tech",
  RGPD_API_URL: "https://api.recette.mediadoc.docaposte-agility.tech",
};

const RGPD_PREPRD = {
  RGPD_FRONT_URL: "https://rgpd.preprod.docaposte-agility.tech",
  RGPD_API_URL: "https://api.rgpd.preprod.docaposte-agility.tech",
};

const RGPD_PRODUCTION = {
  RGPD_FRONT_URL: "https://rgpd.docaposte-agility.tech",
  RGPD_API_URL: "https://api.rgpd.docaposte-agility.tech",
};

const getEnvConfig: (envName: string) => Env = (envName) => {
  switch (envName) {
    case "local":
      return RGPD_DEV;
    case "integration":
      return RGPD_INTEGRATION;
    case "recette":
      return RGPD_RECETTE;
    case "preprod":
      return RGPD_PREPRD;
    case "production":
      return RGPD_PRODUCTION;
    case "local_standalone":
    default:
      return RGPD_DEV_REMOTE_API;
  }
};

export default getEnvConfig(process.env.REACT_APP_ENV || "local_standalone");
