import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { UserHistory } from "../@types";
import ErrorSnackbar from "../components/Snackbar/ErrorSnackbar";
import { getUserAttributes } from "../services/UsersService";

export type HistoriesContextProps = {
  selectedUserId: string;
  setSelectedUserId: Dispatch<SetStateAction<string>>;
  userHistory: UserHistory[] | null;
  setUserHistory: Dispatch<SetStateAction<UserHistory[] | null>>;
  isLoadingHistory: boolean;
  searchUserAttributes: (userId: string) => void;
};

const HistoriesContext = createContext<HistoriesContextProps>({
  selectedUserId: "",
  setSelectedUserId: () => {},
  userHistory: null,
  setUserHistory: () => {},
  isLoadingHistory: false,
  searchUserAttributes: () => {},
});

declare type HistoriesContextProviderProps = {
  children: React.ReactNode;
};

export function HistoriesContextProvider({
  children,
}: HistoriesContextProviderProps): JSX.Element {
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [userHistory, setUserHistory] = useState<UserHistory[] | null>(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserHistory = useCallback(
    (userId: string) => {
      if (userId && userId.length > 1) {
        setIsLoadingHistory(true);
        setUserHistory(null);
        getUserAttributes(userId)
          .then((res) => {
            const data = res?.data || ([] as UserHistory[]);
            setUserHistory(data);
          })
          .catch((e) => {
            setSelectedUserId("");
            setUserHistory([]);
            enqueueSnackbar(<ErrorSnackbar />);
          })
          .finally(() => {
            setIsLoadingHistory(false);
          });
      }
    },
    [enqueueSnackbar]
  );

  const searchUserAttributes: (userId: string) => void = useCallback(
    (userId: string) => {
      fetchUserHistory(userId);
    },
    [fetchUserHistory]
  );

  const value: HistoriesContextProps = useMemo(
    () => ({
      selectedUserId,
      setSelectedUserId,
      userHistory,
      setUserHistory,
      isLoadingHistory,
      searchUserAttributes,
    }),
    [
      selectedUserId,
      setSelectedUserId,
      userHistory,
      setUserHistory,
      isLoadingHistory,
      searchUserAttributes,
    ]
  );
  return (
    <HistoriesContext.Provider value={value}>
      {children}
    </HistoriesContext.Provider>
  );
}

export const useHistoriesContext = () => useContext(HistoriesContext);

export default HistoriesContext;
