import { useMemo } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CobaltIcon } from "da-design-system";
import { useHistory, useRouteMatch } from "react-router-dom";

import { getRouteByKey } from "../../../utils/routes";
import IRoute from "../../../interfaces/route";

declare type AppDrawerItemProps = {
  routeKey: string;
  onClick?: () => void;
};

function AppDrawerItem({ routeKey, onClick }: AppDrawerItemProps): JSX.Element {
  const history = useHistory();
  const route: IRoute | null = useMemo(
    () => getRouteByKey(routeKey),
    [routeKey]
  );
  const routeMatch = useRouteMatch(route?.path || "");

  const handleClick: () => void = () => {
    if (route?.path) {
      history.push(route.path);
      if (onClick) onClick();
    }
  };

  if (route) {
    return (
      <Button
        variant="text"
        startIcon={route.icon && <CobaltIcon name={route.icon} />}
        sx={{
          color: routeMatch ? "cobalt.hover" : "interface",
          backgroundColor: routeMatch ? "cobalt.bleuDigital05" : "inherit",
        }}
        onClick={handleClick}
      >
        <Typography variant="h5">{route.label}</Typography>
      </Button>
    );
  }
  return <div />;
}

export default AppDrawerItem;
