import { ThemeProvider } from "@mui/material/styles";
import { theme } from "da-design-system";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";

import "./App.css";
import Router from "./app/Router";
import KeycloakWrapper from "./app/components/Keycloak/KeycloakWrapper";
import RootSnackbar from "./app/components/Snackbar/RootSnackbar";
import { AuthenticationStateContextProvider } from "./app/contexts/AuthenticationStateContext";

const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthenticationStateContextProvider>
        <KeycloakWrapper>
          <SnackbarProvider
            content={(key, message) => (
              <RootSnackbar id={key} message={message} />
            )}
          >
            <CssBaseline />
            <Router />
          </SnackbarProvider>
        </KeycloakWrapper>
      </AuthenticationStateContextProvider>
    </ThemeProvider>
  );
};

export default App;
