import { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useKeycloak } from "@react-keycloak/web";

import SplashCard from "../../components/Layout/SplashCard";

function LoginPage({ match }: { match: any }): JSX.Element {
  const { keycloak } = useKeycloak();

  const login = useCallback(() => {
    keycloak?.login({ redirectUri: window.location.href });
  }, [keycloak]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "center", md: "flex-end" },
        padding: { xs: 2 },
      }}
    >
      <SplashCard
        sx={{
          marginTop: 9,
          marginRight: { xs: 0, md: 23 },
          width: ["100%", "400px", "500px"],
        }}
        actionText="Connexion"
        action={login}
      >
        <>
          <Typography variant="h2" sx={{ color: "cobalt.lynch" }}>
            Accédez à
          </Typography>
          <Typography variant="h1" sx={{ color: "cobalt.bleuDigital100" }}>
            DA-RGPD
          </Typography>
        </>
      </SplashCard>
    </Box>
  );
}

export default LoginPage;
