import { useCallback, useState } from "react";
import { CobaltIcon, colors, Dialog } from "da-design-system/";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

import TermsImportFile from "./TermsImportFile";
import PDFViewer from "../PDFViewer/PDFViewer";
import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import { createApplicationTerms } from "../../services/TermsService";
import ErrorSnackbar from "../Snackbar/ErrorSnackbar";

declare type TermsUploadModalProps = {
  open: boolean;
  handleClose: () => void;
  onImportSuccess: () => void;
};

function TermsUploadModal({
  open,
  handleClose,
  onImportSuccess,
}: TermsUploadModalProps): JSX.Element {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { selectedApplicationId } = useApplicationsContext();
  const { enqueueSnackbar } = useSnackbar();

  const onImport = useCallback((file: File) => {
    setUploadedFile(file);
  }, []);

  const onClose = useCallback(() => {
    handleClose();
    setUploadedFile(null);
    setIsProcessing(false);
  }, [handleClose]);

  const handleSubmit = useCallback(() => {
    if (uploadedFile) {
      setIsProcessing(true);
      createApplicationTerms(selectedApplicationId, uploadedFile)
        .then(() => {
          onImportSuccess();
          onClose();
        })
        .catch(() => {
          enqueueSnackbar(
            <ErrorSnackbar text="Une erreur est survenue lors du chargement de la CGU" />
          );
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  }, [
    onClose,
    selectedApplicationId,
    uploadedFile,
    onImportSuccess,
    enqueueSnackbar,
  ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <CobaltIcon name="download" />
          </Grid>
          <Grid item>
            <Typography variant="h4"> Création d'une CGU</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          "&.MuiDialogContent-dividers": {
            borderBottom: `1px solid ${colors.grey10}`,
            pt: uploadedFile ? 0 : 3.5,
          },
        }}
      >
        {uploadedFile ? (
          <Box>
            <PDFViewer file={uploadedFile} />
          </Box>
        ) : (
          <TermsImportFile onImport={onImport} />
        )}
      </DialogContent>
      <DialogActions>
        {uploadedFile && (
          <TermsImportFile
            onImport={onImport}
            simple
            btnTitle="Modifier"
            disabled={isProcessing}
          />
        )}
        <LoadingButton
          disabled={!uploadedFile}
          loading={isProcessing}
          onClick={handleSubmit}
          variant="contained"
          loadingPosition="start"
          startIcon={<div />}
          sx={{ pl: isProcessing ? 5 : 3 }}
        >
          Valider l'import
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default TermsUploadModal;
