import { AxiosResponse } from "axios";

import axiosApiInstance from "../utils/axios";
import { ApiEndpointParams, API_ENDPOINT } from "../@types";
import { paramsFromObject } from "../utils/reformator";

export const MAX_FILE_SIZE = 10_485_760;

export const ENDPOINTS: Record<string, API_ENDPOINT> = {
  FETCH_ALL: { url: "/cgus", method: "GET" },
  CREATE: { url: "/cgus", method: "POST" },
  GET_FILE: { url: "/cgus/:cguId/file", method: "GET" },
};

export const getTerms = (): Promise<AxiosResponse<any, any>> =>
  axiosApiInstance({ ...ENDPOINTS.FETCH_ALL, params: {} });

export const getApplicationTerms = (
  applicationId: string,
  endpointParams: ApiEndpointParams
): Promise<AxiosResponse<any, any>> => {
  const formattedEndpointParams = paramsFromObject(endpointParams);
  return axiosApiInstance({
    ...ENDPOINTS.FETCH_ALL,
    params: {
      filter: `applicationId||eq||${applicationId}`,
      sort: "effectiveDate,DESC",
      join: "creator",
      offset: formattedEndpointParams.offset,
      per_page: formattedEndpointParams.per_page,
    },
  });
};

export const createApplicationTerms = (
  applicationId: string,
  file: File
): Promise<AxiosResponse<any, any>> => {
  const formData = new FormData();
  formData.append("applicationId", applicationId);
  formData.append("file", file);
  return axiosApiInstance({
    ...ENDPOINTS.CREATE,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getTermsFile = (
  termsId: string
): Promise<AxiosResponse<any, any>> => {
  return axiosApiInstance({
    ...ENDPOINTS.GET_FILE,
    url: ENDPOINTS.GET_FILE.url.replace(":cguId", termsId),
    responseType: "blob",
  });
};
