import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CobaltIcon } from "da-design-system";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";

declare type SyncListButtonProps = {
  topSpacing?: boolean;
};

function SyncListButton({
  topSpacing = false,
}: SyncListButtonProps): JSX.Element {
  const { refreshWithSynchro } = useApplicationsContext();

  return (
    <Button
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: topSpacing ? 2 : 0,
        py: 1,
        height: "auto",
        px: 2,
      }}
      onClick={refreshWithSynchro}
      variant="text"
    >
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Synchroniser les applications
      </Typography>

      <CobaltIcon
        name="refresh-ccw"
        style={{ fontSize: 20 }}
        sx={{ color: "cobalt.ultramarine", mt: 1 }}
      />
    </Button>
  );
}

export default SyncListButton;
