import Box from "@mui/material/Box";

import Header from "./Header/Header";

declare type LayoutProps = {
  children: React.ReactNode;
  hideHeader?: boolean;
};

function Layout({ children, hideHeader }: LayoutProps): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: "cobalt.light",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!hideHeader && <Header />}
      <Box sx={{ flex: 2, overflow: "scroll" }}>{children}</Box>
    </Box>
  );
}

export default Layout;
