import { useCallback, useEffect, useMemo, useState } from "react";
import { CobaltIcon, colors, Dialog } from "da-design-system/";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

import PDFViewer from "../PDFViewer/PDFViewer";
import { getTermsFile } from "../../services/TermsService";
import ErrorSnackbar from "../Snackbar/ErrorSnackbar";

declare type TermsPreviewModalProps = {
  open: boolean;
  termsId: string;
  onClose: () => void;
};

function TermsPreviewModal({
  open,
  termsId,
  onClose,
}: TermsPreviewModalProps): JSX.Element {
  const [file, setFile] = useState<File | Blob | null | string>(null);
  const { enqueueSnackbar } = useSnackbar();

  const showContent = useMemo(() => open && termsId, [open, termsId]);

  const handleClose = useCallback(() => {
    onClose();
    setFile(null);
  }, [onClose]);

  useEffect(() => {
    if (showContent) {
      getTermsFile(termsId)
        .then((res) => {
          const _file = new File([res.data], "pdf.pdf");
          setFile(_file);
        })
        .catch(() => {
          enqueueSnackbar(
            <ErrorSnackbar text="Une erreur est survenue lors de la récupération de la CGU" />
          );
        });
    }
  }, [showContent, termsId, enqueueSnackbar]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { height: "100%" } }}
    >
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <CobaltIcon name="file-text" />
          </Grid>
          <Grid item>
            <Typography variant="h4"> Prévisualisation d'une CGU</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          "&.MuiDialogContent-dividers": {
            borderBottom: `1px solid ${colors.grey10}`,
            pt: file ? 0 : 3.5,
          },
        }}
      >
        {file ? (
          <PDFViewer file={file} />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TermsPreviewModal;
