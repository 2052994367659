import Keycloak from "keycloak-js";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useKeycloak } from "@react-keycloak/web";

import ErrorSnackbar from "../Snackbar/ErrorSnackbar";
import ProfileCardItem from "./ProfileCardItem";

export default function ProfileCard() {
  const [userProfile, setUserProfile] =
    useState<null | Keycloak.KeycloakProfile>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    keycloak
      ?.loadUserProfile()
      .then((res) => {
        setUserProfile(res);
      })
      .catch(() => {
        setUserProfile(null);
        enqueueSnackbar(
          <ErrorSnackbar text="Une erreur est survenue lors de la récupération de votre profil" />
        );
      });
  }, [enqueueSnackbar, keycloak]);

  return (
    <Box>
      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h3">Informations personnelles</Typography>
        <ProfileCardItem
          label="Nom"
          value={
            userProfile ? userProfile.lastName?.toUpperCase() || "-" : null
          }
        />
        <ProfileCardItem
          label="Prénom"
          value={userProfile ? userProfile.firstName || "-" : null}
        />
        <ProfileCardItem
          label="Email"
          value={userProfile ? userProfile.email || "-" : null}
        />
      </Paper>
    </Box>
  );
}
