import Keycloak from "keycloak-js";
import config from "../config/config";
import { getRealm } from "./reformator";

export interface CustomKeycloakTokenParsed
  extends Keycloak.KeycloakTokenParsed {
  family_name?: string;
  given_name?: string;
}

export const REDIRECT_URI: string = config.RGPD_FRONT_URL;

export const keycloakConfigFromJSON = (): null | Keycloak.KeycloakConfig => {
  const realm = getRealm();
  if (realm) {
    try {
      const file = require(`../config/keycloak/${realm}.json`);

      return {
        url: file["auth-server-url"],
        realm: file["realm"],
        clientId: file["resource"],
      };
    } catch (error) {
      // console.log(" SILENT ERROR NO REALM CONFIG ", error);
    }
  }
  return null;
};

const keycloak: null | Keycloak.KeycloakInstance = keycloakConfigFromJSON()
  ? Keycloak(keycloakConfigFromJSON() || "")
  : null;

export default keycloak;
