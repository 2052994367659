import { useCallback, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { CobaltIcon } from "da-design-system/";

import Box from "@mui/material/Box";
import { Document, Page, pdfjs } from "react-pdf";
import { Theme, useMediaQuery } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

declare type PDFViewerProps = {
  file: File | string | Blob;
};

function PDFViewer({ file }: PDFViewerProps): JSX.Element {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const largeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const mediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );

  const onDocumentLoadSuccess = useCallback(({ numPages }: any): void => {
    setPageNumber(1);
    setNumPages(numPages);
  }, []);

  const canPrevious = useMemo(() => pageNumber !== 1, [pageNumber]);

  const handleGoPreviousPage = useCallback(() => {
    setPageNumber((_page) => _page - 1);
  }, [setPageNumber]);

  const canNext = useMemo(
    () => pageNumber < (numPages || 1),
    [pageNumber, numPages]
  );

  const handleGoNextPage = useCallback(() => {
    setPageNumber((_page) => _page + 1);
  }, [setPageNumber]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          aria-label="previous-page"
          disabled={!canPrevious}
          onClick={handleGoPreviousPage}
          sx={{ color: "cobalt.ultramarine" }}
        >
          <CobaltIcon name="chevron-left" />
        </IconButton>
        <Typography
          variant="caption"
          sx={{ flex: 2, textAlign: "center", px: 3, py: 2 }}
        >
          Page
          <br /> {pageNumber} / {numPages}
        </Typography>
        <IconButton
          aria-label="next-page"
          onClick={handleGoNextPage}
          disabled={!canNext}
          sx={{ color: "cobalt.ultramarine" }}
        >
          <CobaltIcon name="chevron-right" />
        </IconButton>
      </Box>
      <Paper elevation={3}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          error="Erreur lors du chargement de la CGU"
          loading={<CircularProgress />}
        >
          <Page
            scale={mediumScreen ? (largeScreen ? 1.3 : 0.9) : 0.5}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
          />
        </Document>
      </Paper>
    </Box>
  );
}

export default PDFViewer;
