import { AxiosResponse } from "axios";
import { ApiEndpointParams, API_ENDPOINT } from "../@types";
import axiosApiInstance from "../utils/axios";
import { paramsFromObject } from "../utils/reformator";

export const ENDPOINTS: Record<string, API_ENDPOINT> = {
  SEARCH: { url: "/users", method: "GET" },
  GET_HISTORY: { url: "/users/:userId/cgus", method: "GET" },
};

export const searchUsers = (
  search: string,
  endpointParams: ApiEndpointParams
): Promise<AxiosResponse<any, any>> => {
  const formattedEndpointParams = paramsFromObject(endpointParams);
  return axiosApiInstance({
    ...ENDPOINTS.SEARCH,
    params: {
      search,

      offset: formattedEndpointParams.offset,
      per_page: formattedEndpointParams.per_page,
    },
  });
};

export const getUserAttributes = (
  userId: string
): Promise<AxiosResponse<any, any>> => {
  return axiosApiInstance({
    ...ENDPOINTS.GET_HISTORY,
    url: ENDPOINTS.GET_HISTORY.url.replace(":userId", userId),
  });
};
