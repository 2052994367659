import { MouseEvent } from "react";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import UsersListItem from "./UsersListItem";
import { Users } from "../../@types";

declare type UsersListComponentProps = {
  research: string;
  searching: boolean;
  handleChangeResearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchingUsers: () => void;
  isLoading: boolean;
  users: Users[] | null;
  showHistory: (userId: string) => void;
  currentPage: number;
  currentPerPage: number;
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangePerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  total: number | null;
};

const ROWS_PER_PAGE = [5, 10, 25];

function UsersListComponent({
  research,
  searching,
  handleChangeResearch,
  searchingUsers,
  isLoading,
  users,
  showHistory,
  currentPage,
  currentPerPage,
  handleChangePage,
  handleChangePerPage,
  total,
}: UsersListComponentProps): JSX.Element {
  return (
    <Paper>
      <Box
        sx={{
          padding: 3,
          flex: 2,
          height: "100%",
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pb: 4,
        }}
      >
        <Box sx={{ pr: 4 }}>
          <TextField
            value={research}
            onChange={handleChangeResearch}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                searchingUsers();
                e.preventDefault();
              }
            }}
          />
        </Box>
        <Button onClick={searchingUsers} disabled={research.length < 2}>
          Rechercher
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Nom d'utilisateur</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              users && users?.length ? (
                users.map((item) => (
                  <UsersListItem
                    users={item}
                    key={item.id}
                    showHistory={showHistory}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ textAlign: "center", pt: 4, pb: 4 }}
                  >
                    {searching ? "Aucun résultat" : "Recherchez un utilisateur"}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{ textAlign: "center", pt: 4, pb: 4 }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {total !== null && total > 0 && (
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE}
          count={total}
          rowsPerPage={currentPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePerPage}
        />
      )}
    </Paper>
  );
}

export default UsersListComponent;
