import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UserHistory } from "../../@types";
import { useHistoriesContext } from "../../contexts/HistoriesContext";
import UsersHistoryListItem from "./HistoriesListItem";

declare type HistoriesListProps = {
  handlePreviewTerms: (termsId: string) => void;
};
function HistoriesList({
  handlePreviewTerms,
}: HistoriesListProps): JSX.Element {
  const { isLoadingHistory, selectedUserId, userHistory } =
    useHistoriesContext();

  const sortHistory = (a: UserHistory, b: UserHistory): number =>
    new Date(b.cgu.effectiveDate).getTime() -
    new Date(a.cgu.effectiveDate).getTime();

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Application</TableCell>
              <TableCell>Date de mise en application</TableCell>
              <TableCell>Date d'acceptation</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingHistory && selectedUserId ? (
              userHistory && userHistory?.length ? (
                userHistory
                  .sort(sortHistory)
                  .map((item) => (
                    <UsersHistoryListItem
                      userHistoryItem={item}
                      key={item.cgu.id}
                      handlePreviewTerms={handlePreviewTerms}
                    />
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ textAlign: "center", pt: 4, pb: 4 }}
                  >
                    "Aucun résultat"
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{ textAlign: "center", pt: 4, pb: 4 }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default HistoriesList;
