import { useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { useHistory } from "react-router-dom";

import AppDrawerItem from "./AppDrawerItem";
import ProfileBadge from "../Profile/ProfileBadge";
import { getRouteByKey } from "../../../utils/routes";

declare type AppDrawerProps = {
  open: boolean;
  toggleOpen: (state: boolean) => void;
};

function AppDrawer({ open, toggleOpen }: AppDrawerProps): JSX.Element {
  const history = useHistory();
  const profilePath = useMemo(() => {
    const profileRoute = getRouteByKey("PROFILE");
    return profileRoute?.path;
  }, []);

  const toggleDrawer: (state: boolean) => void = useCallback(
    (state) => {
      toggleOpen(state);
    },
    [toggleOpen]
  );

  const onItemClick = () => {
    toggleOpen(false);
  };

  const handleProfileClick = () => {
    if (profilePath) {
      history.push(profilePath);
      toggleOpen(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        toggleDrawer(false);
      }}
      sx={{
        width: "256px",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: "256px",
          boxSizing: "border-box",
          padding: 1,
        },
      }}
    >
      <Toolbar sx={{ height: 64 }} />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
          <AppDrawerItem onClick={onItemClick} routeKey="TERMS" />
          <AppDrawerItem onClick={onItemClick} routeKey="USERS" />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <Divider
            sx={{
              marginTop: 2,
              marginBottom: 2,
              marginRight: 1.5,
              marginLeft: 1.5,
            }}
            orientation="horizontal"
          />
          <ProfileBadge
            onClick={handleProfileClick}
            orientation="vertical"
            sx={{ width: "100%", padding: 1 }}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export default AppDrawer;
