import { MutableRefObject, useCallback, useRef, useState } from "react";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { colors } from "da-design-system/";

import { MAX_FILE_SIZE } from "../../services/TermsService";

export type ErrorReason = "FILE_TOO_LARGE" | "FILE_BAD_FORMAT";

const errorReasonToText: (reason: ErrorReason) => string = (reason) => {
  switch (reason) {
    case "FILE_TOO_LARGE":
      return "Le fichier est trop volumineux";
    case "FILE_BAD_FORMAT":
      return "Le fichier n'est pas au format PDF";
    default:
      return "Impossible de lire le fichier";
  }
};

declare type TermsImportFileProps = {
  onImport: (file: File) => void;
  simple?: boolean;
  btnTitle?: string;
  disabled?: boolean;
};

export default function TermsImportFile({
  onImport,
  simple = false,
  btnTitle = "Importer une CGU",
  disabled = false,
}: TermsImportFileProps) {
  const [importError, setImportError] = useState<null | ErrorReason>(null);
  const fileInput = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClick = useCallback(() => {
    if (fileInput?.current) {
      fileInput.current.click();
    }
  }, [fileInput]);

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setImportError(null);
      const _file: File | undefined = event?.target?.files?.[0];
      if (_file?.type === "application/pdf" && _file?.size < MAX_FILE_SIZE) {
        onImport(_file);
      } else if (_file?.type !== "application/pdf") {
        setImportError("FILE_BAD_FORMAT");
      } else if (_file?.size >= MAX_FILE_SIZE) {
        setImportError("FILE_TOO_LARGE");
      }
    },
    [onImport]
  );

  if (simple) {
    return (
      <Button variant="outlined" onClick={handleClick} disabled={disabled}>
        {btnTitle}
        <input
          type="file"
          hidden
          ref={fileInput}
          accept="application/pdf"
          onChange={handleFileUpload}
        />
      </Button>
    );
  }

  return (
    <Box
      p={1}
      sx={{
        borderRadius: 2,
        border: `1px dashed ${colors.bleuDigital100}`,
        bgcolor: "cobalt.light",
        mb: 3,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ p: 1 }}>
        <Button variant="outlined" onClick={handleClick} disabled={disabled}>
          {btnTitle}
          <input
            type="file"
            hidden
            ref={fileInput}
            accept="application/pdf"
            onChange={handleFileUpload}
          />
        </Button>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="caption">
          Ajouter un document pdf, 10mo max
        </Typography>
        {importError && (
          <Typography variant="body2" sx={{ mt: 1, color: "cobalt.redText" }}>
            {errorReasonToText(importError)}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
