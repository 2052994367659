import { MouseEvent } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { useApplicationsContext } from "../../contexts/ApplicationsContext";
import TermsListItem from "./TermsListItem";
import { Terms } from "../../@types";

const ROWS_PER_PAGE = [5, 10, 25];

declare type TermsListComponentProps = {
  currentPage: number;
  currentPerPage: number;
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangePerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePreviewTerms: (termsId: string) => void;
  isProcessing: boolean;
  terms: Terms[] | null;
  total: number | null;
};

function TermsListComponent({
  currentPage,
  currentPerPage,
  handleChangePage,
  handleChangePerPage,
  handlePreviewTerms,
  isProcessing,
  terms,
  total,
}: TermsListComponentProps): JSX.Element {
  const { selectedApplicationId } = useApplicationsContext();

  if (selectedApplicationId) {
    return (
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date d'upload</TableCell>
                <TableCell>Utilisateur</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isProcessing ? (
                terms && terms?.length ? (
                  terms.map((item) => (
                    <TermsListItem
                      terms={item}
                      key={item.id}
                      handlePreview={handlePreviewTerms}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      sx={{ textAlign: "center", pt: 4, pb: 4 }}
                    >
                      Aucun résultat
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ textAlign: "center", pt: 4, pb: 4 }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {total !== null && total > 0 ? (
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE}
            count={total}
            rowsPerPage={currentPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
          />
        ) : null}
      </Paper>
    );
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
      <Typography variant="body1">
        Veuillez sélectionner une application pour visualiser les CGUs associées
        à celle-ci
      </Typography>
    </Box>
  );
}

export default TermsListComponent;
